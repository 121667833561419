import { AxiosResponse } from "axios";
import ApiService from "@/services";

export default class BidService {
  static getBidList(params: {
    status?: string;
    costingId?: string;
    mfrId?: string;
    sortField?: string;
    sortOrder?: string;
    skip?: number;
    limit?: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/bids", { params });
  }

  static getBidCount(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get("v2/bids/get-bids-count", { params: { rfqId } });
  }

  static createBid(rfqId: string): Promise<AxiosResponse> {
    return ApiService.post(`/v2/bids/${rfqId}`);
  }

  static assignMfr(id: string, mfrId: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/bids/${id}/assign-mfr/${mfrId}`);
  }

  static deleteBid(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/bids/${id}`);
  }
  static withdrawBid(id: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/bids/${id}/withdraw`);
  }
  static duplicateBid(id: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/bids/${id}/copy`);
  }
  static sendBid(id: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/bids/${id}/send`);
  }
  static acceptBid(id: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/bids/${id}/accept`);
  }
  static declineBid(id: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/bids/${id}/decline`);
  }
  static updateRequestedDeliveryDate(
    id: string,
    expectedDeliveryDate: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/bids/${id}/expected-delivery-date`, {
      expectedDeliveryDate,
    });
  }
}
